var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-0 mb-12",attrs:{"fluid":""}},[_c('div',{staticClass:"px-5"},[_c('top-action',{staticClass:"mb-3"}),_c('table-pagination',{staticClass:"mb-3",attrs:{"current-page":_vm.state.page,"pagination-info":_vm.paginationInfo,"per-page":_vm.state.per_page,"selectedReviews":_vm.selectedReviews},on:{"update:currentPage":function($event){return _vm.$set(_vm.state, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.state, "page", $event)},"update:perPage":function($event){return _vm.$set(_vm.state, "per_page", $event)},"update:per-page":function($event){return _vm.$set(_vm.state, "per_page", $event)},"reload":_vm.refetch,"resetSelectedReviews":function($event){_vm.selectedReviews = []}}}),_c('div',{staticClass:"d-flex"},[_c('filter-area',{staticClass:"mb-3"}),_c('div',{staticClass:"ml-4 w-full"},[_c('v-data-table',{staticClass:"pa-3",attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.isFetching,"show-select":"","server-items-length":_vm.paginationInfo.pageTotal,"hide-default-footer":"","item-key":"id"},on:{"click:row":_vm.viewDetail},scopedSlots:_vm._u([{key:"item.rating",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(_vm._s(item.rating))]),_c('v-icon',{staticStyle:{"color":"#FFCB12"},attrs:{"small":""}},[_vm._v("mdi-star")])],1)]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_c('p',{staticStyle:{"overflow-wrap":"break-word","white-space":"pre-line"}},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.truncateText(item.comment, 200)))])])]}},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mr-1",staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.username))]),(item.is_bought)?_c('v-chip',{attrs:{"color":"success","label":"","outlined":"","x-small":""}},[_c('v-icon',{attrs:{"left":"","x-small":""}},[_vm._v(" mdi-check ")]),_vm._v(" Đã mua ")],1):_vm._e()],1)]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.phone)+" "),_c('v-btn',{attrs:{"color":"blue darken-4","fab":"","text":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.phone)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)],1)]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.reviewable_name)+" ")]),_c('span',{staticClass:"text-body-2 grey--text lighten-2"},[_vm._v(" "+_vm._s(_vm.data.model_value)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.STATUS[item.status].color,"outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.STATUS[item.status].label)+" ")])]}},{key:"item.status_process",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.STATUS_PROCESS[item.status_process].label))]),(item.support_label)?_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(item.support_label)+" ")]):_vm._e()]}}],null,true),model:{value:(_vm.selectedReviews),callback:function ($$v) {_vm.selectedReviews=$$v},expression:"selectedReviews"}})],1)],1)],1),_c('modal-review-detail',{key:_vm.$route.query.reviewId,on:{"reload":_vm.refetch}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }