export const STATUS = {
  PENDING: {
    label: "Chờ duyệt",
    color: "amber accent-4",
    value: "PENDING"
  },
  APPROVED: {
    label: "Đã duyệt",
    color: "green darken-2",
    value: "APPROVED"
  },
  HIDDEN: {
    label: "Bị ẩn",
    color: "red darken-2",
    value: "HIDDEN"
  }
};

export const STATUS_PROCESS = {
  DONE: {
    label: "Đã xong",
    color: "green darken-2"
  },
  DOING: {
    label: "Đang xử lý",
    color: "amber accent-4"
  },
  PENDING: {
    label: "Chờ xử lý",
    color: "red darken-2"
  }
};

export const SUPPORT_LABELS = [
  `{{site}} đã liên hệ hỗ trợ ngày {{date}}`,
  "Đánh giá này đã được chuyển tới bộ phận hỗ trợ",
  "Chăm sóc khách hàng đã liên hệ hỗ trợ ngày {{date}}",
  "Bộ phận kỹ thuật đã liên hệ hỗ trợ ngày {{date}}",
  "Bộ phận bảo hành đã liên hệ hỗ trợ ngày {{date}}",
  "Bộ phận bán hàng đã liên hệ hỗ trợ ngày {{date}}"
];
