<template>
  <v-dialog v-model="visible" :width="'720px'" max-width="90%">
    <v-card v-if="isFetching" class="py-2">
      <v-card-text>
        Đang tải dữ liệu

        <v-progress-linear
          class="mb-0"
          color="primary"
          indeterminate
        ></v-progress-linear>
      </v-card-text>
    </v-card>

    <template v-else-if="data">
      <v-toolbar
        class="px-5 pb-0 align-start"
        extension-height="40px"
        flat
        height="56x"
      >
        <v-toolbar-title class="font-weight-bold">
          Đánh giá của {{ data.username }} - {{ data.phone }}

          <v-btn
            color="blue darken-4"
            fab
            text
            x-small
            @click.stop="copyToClipboard(data.phone)"
          >
            <v-icon small>mdi-content-copy</v-icon>
          </v-btn>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          :loading="isLoading"
          class="mr-2"
          color="primary"
          depressed
          @click="onUpdate"
        >
          Lưu
        </v-btn>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-container class="grey lighten-3 pa-5 black--text" fluid>
        <v-card flat>
          <div class="px-5 py-4">
            <div class="d-flex align-center">
              <v-chip
                v-if="data.is_bought"
                color="success"
                label
                outlined
                small
              >
                <v-icon left small>
                  mdi-check
                </v-icon>

                Đã mua
              </v-chip>

              <v-chip v-else label outlined small>
                <v-icon left small>
                  mdi-close
                </v-icon>

                Chưa mua
              </v-chip>

              <v-chip
                v-if="data.order"
                class="ml-2"
                color="primary"
                label
                small
              >
                {{ data.order.code }}
              </v-chip>

              <v-spacer></v-spacer>

              <v-chip :color="STATUS[data.status].color" outlined small>
                {{ STATUS[data.status].label }}
              </v-chip>

              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small v-bind="attrs" v-on="on">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, key) in STATUS"
                    :key="'status-' + key"
                    link
                  >
                    <v-list-item-title @click="data.status = key"
                      >{{ item.label }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <div class="mt-1">
              <div style="font-size: 20px; font-weight: 700;">
                {{ data.reviewable_name }}
              </div>

              <span class="text-body-2 grey--text lighten-2">
                Phiên bản: {{ data.model_value }}
              </span>

              <div class="mt-1">
                <v-rating
                  :value="data.rating"
                  background-color="gray"
                  color="yellow accent-4"
                  dense
                  readonly
                  size="18"
                ></v-rating>
              </div>
            </div>

            <div v-if="data.is_recommend" class="mt-2 d-flex align-center">
              <v-icon class="mr-1" color="red" x-small>
                mdi-heart
              </v-icon>

              <span style="font-size: 12px"
                >Sẽ giới thiệu cho người thân, bạn bè</span
              >
            </div>

            <div class="mt-2" style="white-space: pre-line;">
              <p class="text-body-1" v-html="data.comment"></p>
            </div>

            <div class="mt-2 d-flex flex-wrap" style="gap: 12px">
              <div v-for="(file, index) in data.files" :key="'file-' + index">
                <v-dialog max-width="860px" width="70%">
                  <template v-slot:activator="{ on, attrs }">
                    <div style="width: 80px">
                      <img
                        :src="getImageUrl(file)"
                        alt=""
                        v-bind="attrs"
                        v-on="on"
                      />
                    </div>
                  </template>

                  <img :src="getImageUrl(file)" alt="" class="white" />
                </v-dialog>
              </div>
            </div>
          </div>
        </v-card>

        <v-card class="mt-5" flat>
          <div class="px-5 py-4">
            <div class="d-flex align-center">
              <div style="font-size: 20px; font-weight: 700;">
                Xử lý đánh giá
              </div>

              <v-spacer></v-spacer>

              <v-chip
                :color="STATUS_PROCESS[data.status_process].color"
                outlined
                small
              >
                {{ STATUS_PROCESS[data.status_process].label }}
              </v-chip>

              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small v-bind="attrs" v-on="on">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, key) in STATUS_PROCESS"
                    :key="'status-' + key"
                    link
                  >
                    <v-list-item-title @click="data.status_process = key"
                      >{{ item.label }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <div class="mt-2">
              <div class="text-body-2" style="font-weight: 500">
                Nhãn hỗ trợ (Hiển thị cho khách hàng)
              </div>

              <div class="mt-2 d-flex flex-column">
                <div
                  v-for="(label, index) in supportLabels"
                  :key="'label-' + index"
                  class="mt-2"
                >
                  <v-chip
                    label
                    small
                    v-bind="
                      label === supportLabel
                        ? { color: 'primary', outlined: true }
                        : {}
                    "
                    @click="supportLabel = label"
                    >{{ label }}
                  </v-chip>
                </div>
              </div>

              <div class="mt-2">
                <v-text-field
                  v-model="supportLabel"
                  background-color="white"
                  class="text-body-1"
                  dense
                  filled
                  outlined
                  placeholder="Nhập nhãn hỗ trợ"
                  single-line
                ></v-text-field>
              </div>
            </div>

            <div class="mt-5">
              <div class="text-body-2" style="font-weight: 500">
                Ghi chú xử lý nội bộ
              </div>

              <div class="mt-2">
                <v-textarea
                  v-model="data.internal_note"
                  :rows="3"
                  auto-grow
                  background-color="white"
                  class="text-body-1"
                  dense
                  filled
                  outlined
                  placeholder="CSKH đã liên hệ hỗ trợ khách hàng"
                ></v-textarea>
              </div>
            </div>
          </div>
        </v-card>
      </v-container>
    </template>
  </v-dialog>
</template>

<script>
import dayjs from "dayjs";
import { computed, defineComponent, getCurrentInstance, ref, watch } from "vue";
import { template } from "@thinkvn/utils";
import { useFetchData } from "@thinkvn/composables/lib/useFetchData";

import { useRouteQuery } from "@/core/composables";
import { LIST_MODEL_SITES } from "@/core/constant";

import ReviewService from "../service/index";
import { STATUS, STATUS_PROCESS, SUPPORT_LABELS } from "../constants/index";

export default defineComponent({
  setup() {
    const { reviewId, visible, close, data, isFetching } = useLoadingData();

    const { supportLabel, supportLabels, isLoading, onUpdate } = useUpdateData(
      data
    );

    return {
      reviewId,
      visible,
      close,
      data,
      isFetching,

      supportLabel,
      supportLabels,
      isLoading,
      onUpdate
    };
  },

  data() {
    return {
      STATUS,
      STATUS_PROCESS
    };
  },

  methods: {
    getImageUrl(src) {
      return src;
    }
  }
});

const useLoadingData = () => {
  const instance = getCurrentInstance();
  const reviewId = useRouteQuery("reviewId", null);
  const visible = computed({
    get() {
      return Boolean(reviewId.value);
    },
    set(value) {
      if (!value) {
        close();
      }
    }
  });

  const close = () => {
    reviewId.value = null;
    data.value = null;
  };

  const { fetch, data, isFetching } = useFetchData(
    () => {
      return ReviewService.getReviewById(reviewId.value);
    },
    {
      transform: ({ data: response }) => response.data,
      enabled: () => Boolean(reviewId.value),
      reject: () => {
        close();
        instance.proxy.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Tải dữ liệu không thành công"
          }
        });
      }
    }
  );

  const open = async () => {
    await fetch();
  };

  watch(
    reviewId,
    value => {
      if (value) {
        open();
      } else {
        close();
      }
    },
    { immediate: true }
  );

  return {
    reviewId,
    visible,
    close,
    data,
    isFetching
  };
};

const useUpdateData = data => {
  const instance = getCurrentInstance();

  const currentDate = dayjs().format("DD/MM/YYYY");

  const getTemplate = string => {
    if (!data.value) return string;

    return template(string, {
      site: LIST_MODEL_SITES[data.value.site].text,
      date: currentDate
    });
  };

  const supportLabel = ref("");
  const supportLabels = computed(() => SUPPORT_LABELS.map(getTemplate));

  const initialSupportLabel = () => {
    supportLabel.value = data.value.support_label;
  };

  watch(
    () => data.value.id,
    value => {
      if (value) {
        initialSupportLabel();
      }
    },
    { immediate: true, deep: true }
  );

  const isLoading = ref(false);

  const onUpdate = async () => {
    try {
      isLoading.value = true;

      await ReviewService.updateReview({
        ...data.value,
        support_label: supportLabel.value
      });

      instance.proxy.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Cập nhật đánh giá khách hàng thành công"
        }
      });

      instance.proxy.$emit("reload");
    } catch (e) {
      console.log("update review", e);

      instance.proxy.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Cập nhật đánh giá khách hàng không thành công"
        }
      });
    } finally {
      isLoading.value = false;
    }
  };

  return {
    supportLabel,
    supportLabels,
    isLoading,
    onUpdate
  };
};
</script>
