<template>
  <div class="filter-area">
    <v-card class="tp-filter-scroll pa-5" flat>
      <div>
        <div class="font-weight-bold mb-2">Đánh giá</div>

        <v-checkbox
          v-for="item in 5"
          :key="'rating-' + item"
          v-model="state.rating"
          :label="`${item} sao`"
          :value="item"
          class="mt-1"
          dense
          hide-details
        ></v-checkbox>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Trạng thái xử lý</div>

        <v-checkbox
          v-for="(item, value) in STATUS_PROCESS"
          :key="'STATUS_PROCESS-' + value"
          v-model="state.statusProcess"
          :label="item.label"
          :value="value"
          class="mt-1"
          dense
          hide-details
        ></v-checkbox>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Khách mua hàng</div>

        <v-checkbox
          v-model="state.isBought"
          class="mt-1"
          dense
          hide-details
          label="Đã mua"
        ></v-checkbox>
      </div>
    </v-card>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useRouteQuery } from "@/core/composables";
import { STATUS, STATUS_PROCESS } from "../constants";

export default defineComponent({
  setup() {
    const state = reactive({
      rating: useRouteQuery("rating", []),
      statusProcess: useRouteQuery("statusProcess", []),
      isBought: useRouteQuery("isBought", false)
    });

    return {
      state
    };
  },
  data() {
    return {
      STATUS,
      STATUS_PROCESS
    };
  }
});
</script>

<style lang="scss" scoped>
.filter-area {
  position: sticky;
  top: 80px;
  height: 100%;
  width: 256px;
}
</style>
