<template>
  <v-container class="py-0 mb-12" fluid>
    <div class="px-5">
      <top-action class="mb-3"></top-action>

      <table-pagination
        :current-page.sync="state.page"
        :pagination-info="paginationInfo"
        :per-page.sync="state.per_page"
        class="mb-3"
        :selectedReviews="selectedReviews"
        @reload="refetch"
        @resetSelectedReviews="selectedReviews = []"
      ></table-pagination>

      <div class=" d-flex">
        <filter-area class="mb-3"></filter-area>

        <div class="ml-4 w-full">
          <v-data-table
            v-model="selectedReviews"
            :headers="headers"
            :items="data"
            :loading="isFetching"
            show-select
            :server-items-length="paginationInfo.pageTotal"
            class="pa-3"
            hide-default-footer
            item-key="id"
            @click:row="viewDetail"
          >
            <template v-slot:[`item.rating`]="{ item }">
              <div class="d-flex align-center">
                <span>{{ item.rating }}</span>

                <v-icon small style="color: #FFCB12">mdi-star</v-icon>
              </div>
            </template>

            <template v-slot:[`item.comment`]="{ item }">
              <p style="overflow-wrap: break-word; white-space: pre-line">
                <span class="d-block">{{
                  truncateText(item.comment, 200)
                }}</span>
              </p>
            </template>

            <template v-slot:[`item.username`]="{ item }">
              <div class="d-flex align-center">
                <span class="mr-1" style="white-space: nowrap">{{
                  item.username
                }}</span>

                <v-chip
                  v-if="item.is_bought"
                  color="success"
                  label
                  outlined
                  x-small
                >
                  <v-icon left x-small>
                    mdi-check
                  </v-icon>

                  Đã mua
                </v-chip>
              </div>
            </template>

            <template v-slot:[`item.phone`]="{ item }">
              <div class="d-flex align-center">
                {{ item.phone }}

                <v-btn
                  color="blue darken-4"
                  fab
                  text
                  x-small
                  @click.stop="copyToClipboard(item.phone)"
                >
                  <v-icon small>mdi-content-copy</v-icon>
                </v-btn>
              </div>
            </template>

            <template v-slot:[`item.product`]="{ item }">
              <div class="d-flex align-center">
                {{ item.reviewable_name }}
              </div>

              <span class="text-body-2 grey--text lighten-2">
                {{ data.model_value }}
              </span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <v-chip :color="STATUS[item.status].color" outlined small>
                {{ STATUS[item.status].label }}
              </v-chip>
            </template>

            <template v-slot:[`item.status_process`]="{ item }">
              <div>{{ STATUS_PROCESS[item.status_process].label }}</div>

              <div v-if="item.support_label" class="text-body-2">
                {{ item.support_label }}
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>

    <modal-review-detail
      :key="$route.query.reviewId"
      @reload="refetch"
    ></modal-review-detail>
  </v-container>
</template>

<script>
import { defineComponent, reactive, ref, watch } from "vue";
import { useFetchData } from "@thinkvn/composables/lib/useFetchData";
import { cloneDeep, isEqual, omit } from "@thinkvn/utils";

import { useRouteQuery } from "@/core/composables";

import FilterArea from "./components/FilterArea.vue";
import TablePagination from "./components/TablePagination.vue";
import TopAction from "./components/TopAction.vue";
import ModalReviewDetail from "./components/ModalReviewDetail.vue";

import ReviewService from "./service/index";
import { STATUS, STATUS_PROCESS } from "./constants/index";

export default defineComponent({
  name: "review",

  components: {
    ModalReviewDetail,
    FilterArea,
    TablePagination,
    TopAction
  },

  setup() {
    const state = reactive({
      page: useRouteQuery("currentPage", 1),
      per_page: useRouteQuery("perPage", 25),
      search: useRouteQuery("search", "", value => value || ""),
      rating: useRouteQuery("rating", []),
      status: useRouteQuery("status", ""),
      status_process: useRouteQuery("statusProcess", []),
      is_bought: useRouteQuery("isBought", false, value => Boolean(value))
    });

    const paginationInfo = ref({
      from: 0,
      to: 0,
      pageTotal: 1,
      itemTotal: 0
    });

    const selectedReviews = ref([]);

    const { fetch, data, isFetching } = useFetchData(
      () => ReviewService.getReviews(state),
      {
        transform: ({ data: response }) => {
          const meta = response.meta;

          paginationInfo.value = {
            from: meta.from,
            to: meta.to,
            pageTotal: meta.last_page,
            itemTotal: meta.total
          };

          return response.data;
        }
      }
    );

    watch(
      () => cloneDeep(state),
      (newValue, oldValue) => {
        if (isEqual(newValue, oldValue)) return;

        // reset currentPage về 1 khi thay đổi các state khác
        if (!isEqual(omit(newValue, "page"), omit(oldValue, "page"))) {
          state.page = 1;
        }

        fetch();
      },
      { immediate: true, deep: true }
    );

    return {
      state,
      paginationInfo,
      refetch: fetch,
      data,
      isFetching,
      selectedReviews
    };
  },

  data() {
    return {
      STATUS,
      STATUS_PROCESS,
      headers: [
        {
          text: "Thời gian",
          align: "start",
          sortable: false,
          value: "created_at",
          width: 100
        },
        { text: "Đánh giá", align: "start", value: "rating", sortable: false },
        { text: "Nội dung", value: "comment", sortable: false, width: 400 },
        { text: "Người đánh giá", value: "username", sortable: false },
        { text: "SĐT", value: "phone", sortable: false },
        { text: "Sản phẩm", value: "product", sortable: false },
        { text: "Hiển thị", value: "status", sortable: false },
        { text: "Xử lý", value: "status_process", sortable: false }
      ]
    };
  },

  methods: {
    viewDetail(item) {
      const route = {
        query: {
          ...this.$route.query,
          reviewId: item.id
        }
      };

      this.$router.push(route);
    },
    truncateText(input, length, delimiter = "...") {
      const limit = Number(length);
      if (isNaN(limit)) {
        return delimiter;
      }

      if (input.length <= limit) {
        return input;
      }

      const result = input.substr(0, limit);
      const indexLastSpace = result.lastIndexOf(" ");
      if (indexLastSpace === -1) {
        return delimiter;
      }

      return `${result.substr(0, indexLastSpace)} ${delimiter}`;
    }
  }
});
</script>
