<template>
  <div>
    <div class="d-flex align-center">
      <div class="mr-4">
        <span class="font-weight-bold text-h5">Quản lý đánh giá</span>
      </div>

      <div class="d-flex align-center">
        <div
          v-for="(item, index) in statusList"
          :key="index"
          class="d-flex align-center"
        >
          <v-chip
            :color="status === item.value ? 'white' : 'grey lighten-3'"
            :text-color="status === item.value ? 'primary' : 'grey darken-2'"
            class="font-weight-bold rounded-lg px-4"
            label
            large
            style="height: 36px"
            @click="status = item.value"
          >
            {{ item.label }}
          </v-chip>

          <v-divider
            v-if="index !== statusList.length - 1"
            class="mx-2"
            style="height: 24px; align-self: auto"
            vertical
          ></v-divider>
        </div>
      </div>
    </div>

    <div class="mt-3" style="width: 400px">
      <v-text-field
        v-model="searchText"
        class="rounded-lg text-body-1"
        clearable
        dense
        flat
        full-width
        hide-details
        placeholder="Tìm kiếm theo SĐT, tên người đánh giá"
        prepend-inner-icon="mdi-magnify"
        solo
      >
      </v-text-field>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { debouncedWatch } from "@vueuse/core";
import { useRouteQuery } from "@/core/composables";
import { STATUS } from "../constants/index";

export default defineComponent({
  setup() {
    const status = useRouteQuery("status", "");
    const search = useRouteQuery("search", "");
    const searchText = ref(search.value);

    debouncedWatch(
      searchText,
      value => {
        search.value = value;
      },
      { debounce: 300 }
    );

    return { status, search, searchText, STATUS };
  },
  data() {
    return {
      statusList: [
        {
          value: "",
          label: "Tất cả"
        },
        ...Object.entries(STATUS).map(([value, item]) => ({
          ...item,
          value
        }))
      ]
    };
  }
});
</script>
