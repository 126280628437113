import Api from "@/core/apis";

export default {
  getReviews(data) {
    return Api().post("review/list", data);
  },
  getReviewById(id) {
    return Api().get(`review/detail/${id}`);
  },
  updateReview(data) {
    return Api().put("review", data);
  },
  updateMultipleReview(data) {
    return Api().put("review/multiple", data);
  }
};
