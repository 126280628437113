<template>
  <div v-if="paginationInfo" class="d-flex justify-space-between">
    <div class="d-flex align-center">
      <tp-btn-overflow
        :items="showFilterList"
        :selected-value="perPage"
        btn-class="mr-5 white rounded-lg"
        @select="event => $emit('update:perPage', event.id)"
      ></tp-btn-overflow>

      <tp-btn-overflow
        v-if="selectedReviews.length > 0"
        btn-class="white rounded-lg"
        :active-item="false"
        :items="actionList"
        placeholder="Thao tác"
        @select="selectAction($event)"
      ></tp-btn-overflow>
      <div class="ml-3" v-if="selectedReviews.length > 0">
        Đã chọn <strong>{{ selectedReviews.length }}</strong> đánh giá
      </div>
    </div>

    <div class="action-row--col d-flex align-center">
      <div v-if="paginationInfo.itemTotal" class="text-body-2">
        {{ paginationInfo.from }} - {{ paginationInfo.to }} trong số
        {{ paginationInfo.itemTotal }}
      </div>

      <v-pagination
        :length="paginationInfo.pageTotal"
        :total-visible="5"
        :value="currentPage"
        @input="event => $emit('update:currentPage', event)"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ReviewService from "../service/index";
import { STATUS } from "../constants/index";

export default defineComponent({
  props: {
    currentPage: { type: Number },
    perPage: { type: Number },
    paginationInfo: { type: Object },
    selectedReviews: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showFilterList: [
        { id: 25, text: "Hiển thị 25" },
        { id: 50, text: "Hiển thị 50" },
        { id: 100, text: "Hiển thị 100" }
      ],
      actionList: [
        { id: "pending", text: "Bỏ duyệt" },
        { id: "approved", text: "Duyệt" },
        { id: "hidden", text: "Ẩn" }
      ]
    };
  },

  methods: {
    selectAction(val) {
      this.handleUpdateReviews(val.id);
    },

    async handleUpdateReviews(type) {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title:
            type === "pending"
              ? "<span>Bỏ duyệt đánh giá</span>"
              : type === "approved"
              ? "<span>Duyệt đánh giá</span>"
              : "<span>Ẩn đánh giá</span>",
          message:
            type === "pending"
              ? "Đánh giá đang chọn sẽ bị bỏ duyệt bây giờ. Bạn có chắc chắn muốn tiếp tục?"
              : type === "approved"
              ? " Đánh giá đang chọn sẽ được duyệt bây giờ. Bạn có chắc chắn muốn tiếp tục?"
              : "Đánh giá đang chọn sẽ bị ẩn bây giờ. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color:
              type === "pending"
                ? "red accent-2"
                : type === "approved"
                ? "primary"
                : "",
            text:
              type === "pending"
                ? "Bỏ duyệt"
                : type === "approved"
                ? "Duyệt"
                : "Ẩn",
            onClickHandler: async () => {
              try {
                const dataUpdate = this.$props.selectedReviews.map(review => ({
                  ...review,
                  status:
                    type === "pending"
                      ? STATUS.PENDING.value
                      : type === "approved"
                      ? STATUS.APPROVED.value
                      : STATUS.HIDDEN.value
                }));

                await ReviewService.updateMultipleReview(dataUpdate);

                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Cập nhật đánh giá khách hàng thành công"
                  }
                });
              } catch (error) {
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Cập nhật đánh giá khách hàng không thành công"
                  }
                });
              } finally {
                this.$emit("reload");
                this.$emit("resetSelectedReviews");
              }
            }
          }
        }
      });
    }
  }
});
</script>
